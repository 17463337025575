.controle-pessoas {
  background: #fff;
  padding: 20px;
  border-radius: 8px; /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Borda com cor suave */
  height: 550px;
  min-width: 80%;
  max-width: 100%;
}

.header-controle-pessoas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}

.headerEsquerda-controle-pessoas {
  width: 50%;
  height: 100%;
}

.headerDireita-controle-pessoas {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.filtrar-nome-controle-pessoas {
  flex: 1; /* Faz o input ocupar o espaço restante */
  margin-top: 12px;
}

.botao-controle-pessoas {
  width: 150px;
  height: 35px;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.botao-bloquear {
  background-color: green;
}

.botao-desbloquear {
  background-color: red;
}

.botao-controle:hover {
  opacity: 0.8;
}

.controle-pessoas-container {
  width: 100%;
}

.tabela-controle-acesso {
  /* background-color: #0056b3; */
  margin-left: 10px;
  margin-right: 10px;
  max-height: 480px;
  overflow-y: auto; /* Adiciona barra de rolagem vertical quando necessário */
}
