
.botoes-planilha {
    /* background-color: blue; */
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column; /* Disposição vertical */
    align-items: center; /* Centraliza os botões horizontalmente */
    justify-content: center; /* Centraliza os botões verticalmente */
    gap: 15px; /* Espaçamento entre os botões */
    height: 100%; /* Ocupar toda a altura do container pai */
    position: relative;
  }
  
  .botoes-planilha button,
  .botoes-planilha .enviar-excel-botao {
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px; /* Espaçamento interno */
    width: auto; /* Ajusta ao conteúdo */
    height: auto; /* Ajusta ao conteúdo */
    font-size: 1rem; /* Tamanho do texto */
    text-align: center;
    border: none;
    border-radius: 5px;
    background-color: #d7d7d7;
    cursor: pointer;
    transition: background-color 0.3s; /* Animação para o hover */
    color: black;
  }
  
  .botoes-planilha button:hover,
  .botoes-planilha .enviar-excel-botao:hover {
    background-color: #b5b5b5; /* Cor ao passar o mouse */
  }
  
  .botoes-planilha button i,
  .botoes-planilha .enviar-excel-botao i {
    margin-right: 8px; /* Espaço entre o ícone e o texto */
  }
  
  .botao-inferior-planilha {
    display: flex;
    flex-direction: row;
    align-items: center; /* Alinhar itens na vertical */
    justify-content: center; /* Centralizar itens na horizontal */
    width: 100%;
    position: absolute; /* Tornar a posição relativa ao pai */
    bottom: 0px; /* Ficar no canto inferior */
  }

  #botao-agendar-formulario {
    width: 90%;
    margin-bottom: 20px;
    margin-right: 0;
    background-color: var(--corBotao);
    font-size: 1rem;
    color: white;
  }

  #botao-agendar-formulario:hover{
    background-color: rgb(8, 85, 8);
  }

  #download-planilha, #enviar-planilha {
    box-sizing: border-box;

    margin-right: 0;
    width: 250px;
  }

  .div-botao-arquivo {
    width: 400px;
    display: flex;
    justify-content: center;
  }