.alerta {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  height: auto; /* Ajuste a altura automaticamente com base no conteúdo */
  text-align: center;
  padding-bottom: 20px;
}

.header {
  height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header.sucesso {
  background-color: #00b300;
  
}

.header.erro {
  background-color: #d10000;
}

.alerta-conteudo {
  display: flex;
  flex-direction: column; /* Alinha os itens em uma coluna */
  align-items: center;
  margin: 20px;
}

.alerta-icone {
  width: 80px;
  height: 80px;
  margin-bottom: 20px; /* Espaço entre o ícone e a mensagem */
}

.alerta-mensagem {
  font-size: 18px;
  margin-bottom: 20px; /* Espaço entre a mensagem e o botão */
}

.alerta-botao {
  padding: 10px 20px;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}


.alerta-botao.erro {
  background-color: rgb(209, 0, 0);
}

.alerta-botao:hover.erro {
  background-color: rgb(170, 3, 3);
}

.alerta-botao.sucesso {
  background-color: rgb(0, 179, 0);
}

.alerta-botao:hover.sucesso {
  background-color: rgb(0, 148, 0);
}