.confirmacao-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmacao-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
  }
  
  .confirmacao-imagem {
    max-width: 80px; /* Ajuste o tamanho conforme necessário */
  }
  
  .confirmacao-mensagem {
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .confirmacao-botoes {
    display: flex;
    justify-content: space-around;
  }
  
  .confirmacao-botao {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  
  .confirmacao-botao.cancel {
    background-color: #d9534f;
    color: white;
  }
  .confirmacao-botao.cancel:hover{
    background-color: #c9302c;
  }
  