/* Adicione ao seu arquivo CSS */
.table-status-circle {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: currentColor;
  vertical-align: middle;
  margin-right: 8px;
}

.tela {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
  /* height: 100%; */
}

.registro-saida {
  background: #fff;
  padding: 20px;
  border-radius: 8px; /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Borda com cor suave */
  width: 95%;
  display: inline;
  /* height: 800px;
  max-height: 800px; */
}

.header-registroSaida {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.header-esquerda, .header-direita {
  width: 50%;
}

.header-direita {
  position: relative; /* Necessário para que o posicionamento absoluto do input seja relativo à div-pai */

  /* background-color: blue; */
}

.filtrar-nome {
  margin-top: 15px;
  max-width: 100%;
  /* position: absolute; */
  right: 0;
}

#semaforo {
  /* background-color: blue; */
  text-align: center;
}

#registrar, #autorizar {
  text-align: end;
  /* background-color: blue; */
}

.registrar-botao:disabled {
  background-color: grey; /* Cor do botão quando desativado */
  cursor: not-allowed; /* Ícone de bloqueado quando desativado */
}

.registrar-botao:disabled:hover {
  background-color: grey; /* Mantém a cor cinza ao passar o mouse */
  cursor: not-allowed; /* Ícone de bloqueado ao passar o mouse */
}