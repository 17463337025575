.central-controle {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1300px;
  text-align: center;
}

.menu-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 40px;
}

.menu-card {
  background: rgb(248, 248, 248);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  width: 200px;
  height: 200px;
  display: flex; /* Adiciona flexbox */
  flex-direction: column; /* Alinha itens em coluna */
  align-items: center; /* Centraliza itens horizontalmente */
  justify-content: center; /* Centraliza itens verticalmente */
}

.menu-card img {
  width: 100px;
  height: 100px;
}

.menu-card h2 {
  margin-top: 15px;
  font-size: 18px;
  color: #333;
}

.menu-card:hover {
  transform: translateY(-5px);
}

.icone-carregando {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.menu-card.desativado {
  background: #dbdbdb; /* Cor de fundo para indicar que está desativado */
  cursor: not-allowed; /* Muda o cursor para indicar que não é clicável */
  opacity: 0.6; /* Adiciona opacidade para indicar inatividade */
}

.menu-card.desativado:hover {
  transform: none; /* Remove o efeito de hover para cards desativados */
}
