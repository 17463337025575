.container-autorizacao-entrada {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;
  /* background-color: blue; */
  height: calc(100vh - 100px);
}

.visitantes-autorizados {
  box-sizing: border-box;
  margin: 0px 10px 10px 10px;
  background-color: rgb(252, 252, 252);
  height: calc(100% - 10px);
  width: 70%;
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Borda com cor suave */
}

.autorizacao-entrada {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 30%;
  margin: 0px 10px 10px 10px;
  background-color: rgb(252, 252, 252);
  height: calc(100% - 10px);
  padding: 0px;
  border-radius: 8px;
  /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Borda com cor suave */
  /* background-color: blue; */
}

.agenda-visitante-padding {
  padding: 10px 20px 0px 20px;
}

.menu-agendar-visitante {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
}

.opcao {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  font-weight: bold;
}

.opcao:hover {
  background-color: #f1f1f1;
}

.selecionada {
  border-bottom: 3px solid var(--corBotao);
  color: var(--corBotao);
}

.corpo-agenda-visitante{
  flex-grow: 1;
}


.visitantes-autorizados {
  width: 69%;
}

.body-autorizacao-entrada {
  display: flex;
}

.formulario-autorizacao-entrada {
  /* background-color: pink; */
  width: 100%;
}

.input-autorizacao-entrada {
  min-width: 94%;
}

.data-hora-formulario-entrada {
  display: flex;
  justify-content: flex-start;
}

#data-autorizacao-entrada {
  width: 215px;
  /* background-color: red; */
}

#horario-autorizacao-entrada {
  width: 100px;
  /* background-color: blue; */
}

.imagem-autorizacao-entrada {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagem-autorizacao-entrada img {
  max-width: 80%;
  max-height: 80%;
}

#botao-autorizacao-entrada {
  margin-top: 20px;
  width: 100%;
}

.titulo-autorizados {
  width: 40%;
}

.filtrar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Alinha os itens à direita do container */
  gap: 10px;
  /* Espaçamento entre os filtros */
  width: 60%;
}

.filtrar-data {
  flex: 0 0 30%;
  /* 30% de largura */
}

.filtrar-visitantes-usuario {
  flex: 0 0 60%;
  /* 60% de largura */
}

.filtrar-data label {
  display: block;
  margin-bottom: 5px;
}

.filtrar-data input,
.filtrar-visitantes-usuario input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  /* Inclui padding e border na largura total */
}

.filtrar-visitantes-usuario input {
  width: 100%;
  /* Ajusta para ocupar 60% do container */
}

.tabela-visitantesAgendados td:last-child {
  text-align: right;
  /* Alinha o conteúdo da célula à direita */
  padding-right: 10px;
  /* Ajusta o espaçamento interno à direita */
}

.botoes-icones {
  display: flex;
  justify-content: flex-end;
  /* Alinha os botões ao final do contêiner */
}

.action-button {
  border: none;
  background-color: transparent;
  border-radius: 50%;
  padding: 5px;
  /* Ajusta o padding para economizar espaço */
  cursor: pointer;
  font-size: 20px;
  /* Ajusta o tamanho do ícone */
  margin-left: 5px;
}

.edit-button {
  color: rgb(199, 130, 1);
  /* Cor para o botão de editar */
  margin-right: 5px;
  /* Espaço entre os botões */
}

.delete-button {
  color: #dc3545;
  /* Cor para o botão de excluir */
}

.block-button {
  color: #dc3545;
  /* Cor para o botão de excluir */
}

.unblock-button {
  color: var(--corBotao);
}

.edit-button:hover {
  background-color: rgb(199, 130, 1);
  color: white;
}

.delete-button:hover {
  background-color: #dc3545;
  color: white;
}

.block-button:hover {
  background-color: #dc3545;
  color: white;
}

.unblock-button:hover {
  background-color: var(--corBotao);
  color: white;
}

.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
