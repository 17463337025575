.modal-content-usuarios {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 40%;
    max-width: 600px;
    max-height: 80%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group-duo {
    display: flex;
    gap: 10px;
  }
  
  .form-group-duo .form-group {
    flex: 1;
  }
  
  #modalUsuarios-botao-confirmar,
  #modalUsuarios-botao-fechar {
    width: 100%;
    margin-top: 10px;
    align-self: flex-end;
  }
  
  #modalUsuarios-botao-fechar {
    background-color: #d9534f;
  }
  
  #modalUsuarios-botao-fechar:hover {
    background-color: #c9302c;
  }
  