/* src/style.css */

/* Garante que o body e html ocupem toda a altura da tela */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .navbar {
    flex-shrink: 0; /* Garante que a navbar não encolha */
  }
  
  .content {
    flex: 1; /* Faz com que o content ocupe o espaço restante */
    /* padding: 20px; Adiciona algum padding interno */
    overflow-y: auto; /* Adiciona rolagem se necessário */
    background-color: rgb(228, 240, 206);
    font-family: 'Roboto', sans-serif; /* Aplica a fonte Roboto ao Navbar */

  }

  .content h1 {
    font-size: 28px;
  }

  :root {
    --corBotao: #00874d;
    --corBotaoHover: #006e40;
  }
  