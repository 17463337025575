.formulario-autorizacao-entrada{
    box-sizing: border-box;
    height: 100%;
    position: relative;
}

.campos-formulario-agendar{
    padding: 20px;
}

.botao-inferior-formulario {
  display: flex;
  flex-direction: row;
  align-items: center; /* Alinhar itens na vertical */
  justify-content: center; /* Centralizar itens na horizontal */
  width: 100%;
  position: absolute; /* Tornar a posição relativa ao pai */
  bottom: 0px; /* Ficar no canto inferior */
}

#botao-agendar-formulario {
  width: 90%;
  margin-bottom: 20px;
  margin-right: 0;
  background-color: var(--corBotao);
  font-size: 1rem;
  color: white;
}

#botao-agendar-formulario:hover{
    background-color: rgb(8, 85, 8);
  }
