.nenhum-registro {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Ajuste conforme necessário */
  font-size: 18px;
  color: #555; /* Cor do texto */
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.icone-info {
  margin-right: 10px; /* Espaço entre o ícone e o texto */
  font-size: 24px; /* Tamanho do ícone */
  color: #038137; /* Cor do ícone */
}
