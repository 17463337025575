/* src/pages/CadastroPessoa.css */

.tela {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


.cadastro-pessoa {
  background: #fff;
  padding: 20px;
  border-radius: 8px; /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Borda com cor suave */
  width: 100%;
  max-width: 1000px;
}

.cadastro-pessoa h1 {
  margin-bottom: 20px;
  margin-left: 10px;
}

.painel {
  display: flex;
  justify-content: space-between;
}

.esquerda {
  width: 50%;
}

.direita {
  display: flex;
  flex-direction: column; /* Alinha os itens em coluna */
  align-items: center; /* Centraliza os itens horizontalmente */
  justify-content: center; /* Opcional: Centraliza os itens verticalmente, se necessário */
  width: 50%; /* Ajuste conforme necessário */
  padding: 10px; /* Adiciona algum espaço interno */
  margin-top: 20px
}

.foto-moldura {
  width: 180px; /* Ajuste o tamanho conforme necessário */
  height: 180px; /* Ajuste o tamanho conforme necessário */
  border: 2px dashed #ccc; /* Adiciona uma borda de exemplo */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* Espaço abaixo da moldura da foto */
  
}

.foto-preview, .webcam {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.foto-options {
  display: flex;
  flex-direction: column; /* Alinha os itens verticalmente */
  gap: 10px; /* Espaço entre os itens */
  align-items: center; /* Centraliza os itens horizontalmente */
}

.foto-options button {
  padding: 8px 16px; /* Adiciona algum espaço interno aos botões */
  background-color: #00874d; /* Cor de fundo dos botões */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.foto-options button:hover {
  background-color: #006e40;/* Cor de fundo ao passar o mouse */
}

.foto-options input[type="file"] {
  padding: 8px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.camera-error {
  color: red;
  margin-top: 10px;
  text-align: center; /* Centraliza o texto dentro do elemento de erro */
}

.botao-cadastrar {
  text-align: center;
  margin-top: 30px;
}

.botao-interno {
  width: 99%;
  font-weight: bold;
}

.erro-mensagem {
  color: red;
  margin-bottom: 10px;
}