.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
}

.content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-height: 90%;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 70px;

  display: grid;
  grid-template-areas:
    "cabecalho"
    "formulario";
}

.cabecalho {
  grid-area: cabecalho;
}

.form {
  grid-area: formulario;
  display: grid;

  grid-template-columns: 1fr 3.7fr;

  grid-template-areas:
    "esquerda inputLocais"
    "areaBotoes areaBotoes";

  column-gap: 40px;
}

.esquerda {
  grid-area: esquerda;
}

.inputNome {
  grid-area: inputNome;
}

.inputCpf {
  grid-area: inputCpf;
}

.inputUsuario {
  grid-area: inputUsuario;
}

.inputSenha {
  grid-area: inputSenha;
}

.inputPrivilegio {
  grid-area: inputPrivilegio;
}

.inputLocais {
  grid-area: inputLocais;
}

.areaBotoes {
  grid-area: areaBotoes;
}

.h2 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
}

.label {
  display: block;
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 18px;
}

.textoCard {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}


.input,
.select {
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.input[type="text"] {
  max-width: 100%;
}

.select {
  margin-bottom: 15px;
}

.button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.button:hover {
  background-color: #0056b3;
}

.button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Locais de Destino - Cards */
.local-destino-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.local-destino-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer; /* Aplica o ponteiro ao contêiner */
  transition: background-color 0.3s, transform 0.3s;
  text-align: center;
  background-color: #f9f9f9;
}

.local-destino-card * {
  cursor: inherit; /* Herda o cursor do contêiner */
}

.local-destino-card:hover {
  transform: translateY(-5px);
}

.local-destino-card.selected {
  background-color: var(--corBotao); /* Verde para selecionado */
  color: white;
  border-color: #218838;
}

.local-destino-card label {
  font-weight: bold;
  font-size: 11px;
  margin-top: 5px;
}


/* Loading
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
