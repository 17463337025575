
.autorizacao-saida{
  background: #fff;
  padding: 20px;
  border-radius: 8px; /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Borda com cor suave */
  min-width: 60%;
  max-width: 100%;
}


.header-autorizarSaida {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}


.headerEsquerda-autorizarSaida{
  width: 50%;
  height: 100%;
}




.headerDireita-AutorizarSaida{

  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.espacobotao-adicionarRegistro {
  display: flex; /* Usa Flexbox para layout */
  align-items: center; /* Centraliza verticalmente os itens */
  justify-content: center; /* Opcional: centraliza horizontalmente os itens */
  min-width: 30%;
}

.filtrar-nome-autorizarSaida {
  flex: 1; /* Faz o input ocupar o espaço restante */
  margin-top: 12px;
}

.form-observacoes-modal {
  margin: 10px;
  /* background-color: red; */
}

.form-observacoes-modal textarea {
  width: 94%; /* Define a largura fixa */
  height: 40px; /* Define a altura fixa */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #5c5c5c;
  border-radius: 4px;
  resize: none; /* Desabilita o redimensionamento */
  overflow: auto; /* Adiciona barra de rolagem se necessário */
}

.form-observacoes-modal label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
  
.botao-adicionarRegistro-autorizarSaida  {
  background-color: rgb(199, 130, 1);
}

.botao-adicionarRegistro-autorizarSaida:hover {
  background-color: rgb(155, 101, 2);
}

.modal-content-registrar-autorizacao {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  max-height: 82%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

#registrarAutorizacao-botao-confirmar {
  width: 100%;
  margin-top: 10px;
  align-self: flex-end;
}

#registrarAutorizacao-botao-fechar {
  width: 100%;
  margin-top: 10px;
  background-color: #d9534f;
  align-self: flex-end;
}
#registrarAutorizacao-botao-fechar:hover {
  background-color: #c9302c;
}