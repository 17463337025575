/* Reset básico */

.tela-login {
  .login-reset {
  display: flex;
  height: 100vh; /* Faz o container ocupar toda a altura da tela */
}
}

.login-reset * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.login-container {
  flex: 1; /* Faz a div login-container ocupar o máximo de espaço disponível */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login-background {
  width: 50%; /* Define a largura da div login-background, ajuste conforme necessário */
  background-color: #004d00; /* Cor verde escura */
  background-image: url("../../assets/backgroundLud.png");
  background-size: cover;
  background-position: center;
}

/* Caixa de login */
.login-box {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

/* Título */
.login-box h2 {
  margin-bottom: 20px;
  color: #333;
}

/* Grupo de inputs */
.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Contêiner de senha */
.password-container {
  position: relative;
}

.password-container input {
  width: 100%;
  padding-right: 40px; /* Espaço para o ícone */
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #333;
}

/* Botão de login */
.login-button {
  width: 100%;
  padding: 10px;
  background: var(--corBotao);
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background: var(--corBotaoHover)
}


.login-icon {
  width: 60px; /* Ajuste o tamanho do ícone conforme necessário */
  height: auto;
}