/* style.css */

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;
}

.container-esquerdo-completo {
  margin-left: 10px;
  background-color: rgb(252, 252, 252);
  width: 45%;
  height: 550px;
  padding: 20px;
  border-radius: 8px; /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Borda com cor suave */
}

.container-esquerdo-rgentrada-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.rgentrada-header-botoes {
  display: flex;
  justify-content: space-between;
}

.botao-editar button {
  background-color: rgb(199, 130, 1);
}

.botao-editar button:hover {
  background-color: rgb(155, 101, 2);
}

.registro-entrada-titulo h1 {
  margin: 0;
  font-size: 24px;
}

.container-esquerdo {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
}
.esquerda-esquerda {
  /* background-color: green; */
  width: 50%;
}
.esquerda-direita {
  /* background-color: blue; */
  width: 50%;
}

.container-direito {
  background-color: rgb(252, 252, 252);

  margin-right: 10px;
  width: 47%;
  height: 550px;
  border-radius: 8px; /* Arredonda os cantos */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.header-direito {
  display: flex;
  justify-content: space-between; /* Espaça os itens nos cantos */
  margin-bottom: 15px;
}

.titulo-autorizados {
  flex: 1; /* Permite que o título ocupe o espaço restante */
}

.filtrar-visitantes {
  flex: 1; /* Permite que a área de filtro ocupe o espaço restante */
  display: flex; /* Adiciona flexbox para alinhar o input */
  justify-content: flex-end; /* Alinha o input à direita */
  align-items: center; /* Alinha o input verticalmente no centro */
}

.filtrar-visitantes input {
  width: 80%; /* Ajuste conforme necessário */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.container-esquerdo h1,
.container-direito h1 {
  margin-bottom: 20px;
  margin-left: 10px;
}

.tabela-visitantes {
  /* background-color: #0056b3; */
  margin-left: 10px;
  margin-right: 10px;
  max-height: 441px;
  overflow-y: auto; /* Adiciona barra de rolagem vertical quando necessário */
}

input[readonly] {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.form-group,
.form-observacoes {
  margin: 10px;
  /* background-color: red; */
}

.input-observacoes {
  width: 100%;
}

.form-group label,
.form-observacoes label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 94%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #5c5c5c;
  border-radius: 4px;
}

.form-observacoes textarea {
  width: 97%; /* Define a largura fixa */
  height: 40px; /* Define a altura fixa */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #5c5c5c;
  border-radius: 4px;
  resize: none; /* Desabilita o redimensionamento */
  overflow: auto; /* Adiciona barra de rolagem se necessário */
}

.foto-container {
  width: 140px;
  height: 140px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 2px auto 15px auto; /* margin-top, margin-right/left, margin-bottom */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f9f9f9;
}

.foto-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.foto-placeholder {
  text-align: center;
  color: #999;
}

button {
  background-color: #00874d;
  color: white;
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-right: 10px;
}

button:hover {
  background-color: #006e40;
}

.botao-registrar {
  text-align: center;
  margin-top: 30px;
  align-self: flex-end;

}

.botao-registrar-interno {
  width: 97%;
  margin-left: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

tr:hover {
  background-color: #f1f1f1;
}

/* Estilos para o modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  max-height: 65%;
  display: flex;
  flex-direction: column;
}

.modal-senha-editar {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-modal-senha {
  display: flex;
  flex-direction: column;
}

#botao-senha-editar {
  margin-top: 10px;
  margin-right: 0px;
}

.modal-table-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.header-modal {
  display: flex;
}

.modal-titulo {
  width: 50%;
}

.modal-filtro {
  width: 50%;
  margin-top: 5px;
}

.modal-filtro input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

.modal table {
  width: 100%;
}

.modal th,
.modal td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.modal tr:hover {
  background-color: #f1f1f1;
}

.modal button {
  background-color: #00874d;
  color: white;
  cursor: pointer;
  border: none;
  padding: 10px;
  border-radius: 4px;
}

.modal button:hover {
  background-color: #006e40;
}

#pesquisarPessoas-button-fechar {
  align-self: flex-end;
  margin-top: 10px;
  background-color: #d9534f;
  width: 100%;
}

#pesquisarPessoas-button-fechar:hover {
  background-color: #c9302c;;
}

.cadastrar-button {
  background-color: red;
}

#editarPessoa-botao-confirmar {
  width: 100%;
  margin-top: 30px;
  align-self: flex-end;
}

#editarPessoa-botao-fechar {
  width: 100%;
  margin-top: 20px;
  background-color: #d9534f;
  align-self: flex-end;
}
#editarPessoa-botao-fechar:hover {
  background-color: #c9302c;
}


.modalEditar-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 50%;
  max-height: 70%;
  display: flex;
  flex-direction: column;
}

.modalEditar-conteudo {
  display: flex;
  justify-content: space-between;
}

.modalEditar-formulario {
  width: 100%;
}

.modalEditar-enviarImagem {
    display: flex;
    flex-direction: column; /* Alinha os itens em coluna */
    align-items: center; /* Centraliza os itens horizontalmente */
    justify-content: center; /* Opcional: Centraliza os itens verticalmente, se necessário */
    padding: 10px; /* Adiciona algum espaço interno */

}


.modalEditar-fotoMoldura {
  width: 170px; /* Ajuste o tamanho conforme necessário */
  height: 170px; /* Ajuste o tamanho conforme necessário */
  border: 2px dashed #ccc; /* Adiciona uma borda de exemplo */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* Espaço abaixo da moldura da foto */
  
}

.tabela-clicavel tbody tr:hover {
  cursor: pointer;
}

.tabela-clicavel .linha-bloqueada {
  background-color: #f8d7da; /* Tom de vermelho claro para a linha bloqueada */
  color: #721c24; /* Texto em vermelho escuro para contraste */
  cursor: not-allowed; /* Cursor de não permitido */
}

.tabela-clicavel .linha-bloqueada td {
  pointer-events: none; /* Desativa a interação com os elementos da linha */
}



.modal-senha-editar >.close-button {
  position: absolute;
  top: 5%;
  right: 0px;
  background: red;
  color: white;
  border: none;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

.modal-senha-editar > .close-button:hover {
  background: darkred;
}