/* src/components/style.css */

.navbar-completa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #006130; /* Cor de fundo da navbar */
  padding: 0 10px; /* Ajuste o padding conforme necessário */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #006130;
  padding: 10px 0;
  height: 30px;
  font-family: 'Roboto', sans-serif;
  flex: 1;
}

.nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 10px;
  border-radius: 8px;
  background-color: #00874d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.nav-button:hover {
  background-color: #006e40;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

.nav-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  margin-top: 20px;
}

.nav-button span {
  font-size: 18px;
  margin-top: 4px;
}

.nav-welcome {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  margin-left: auto; /* Garante que o texto e o botão sejam alinhados à direita */
}

.logout-button {
  background-color: #d9534f;
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  transition: background-color 0.3s, transform 0.3s;
  margin-right: 50px;
}

.logout-button:hover {
  background-color: #c9302c;
  transform: scale(1.05);
}
