.modal-content-editarVisitante {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    min-width: 30%;
    max-height: 65%;
    display: flex;
    flex-direction: column;
  }