.menu-abas {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;
}

.aba {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  font-weight: bold;
}

.aba:hover {
  background-color: #f1f1f1;
}

.selecionada {
  border-bottom: 3px solid var(--corBotao);
  color: var(--corBotao);
}

.espaco-adicionarRegistro {
  display: flex;
  justify-content: flex-end;
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}
